import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header";
import { useEffect, useState } from "react";
import Banner from "../components/Banner";
import http from "../utils/http.utils";
import DrawerMenu from "../components/DrawerMenu/DrawerMenu";
import Loader from "../components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { getChildrens, getTree, hasChildrens } from "../utils/tree.utils";
import Breadcrumbs from "../components/Breadcrumbs";
import NavBloc from "../components/NavBloc";
import { useLocale } from "../context/LocaleContext";
import translateKey from "../utils/translateKey";
import iconPlay from "../assets/icons/picto-play.svg";
import { isChannel } from "../utils/isChannel";

export default function Root() {
  const navigate = useNavigate();
  const locale = useLocale();
  const location = useLocation();

  const { t } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [allNavs, setAllNavs] = useState(null);
  const [navChilds, setNavChilds] = useState(null);
  const [currentNav, setCurrentNav] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [navChildsHasSubtitle, setNavChildsHasSubtitle] = useState(false);

  const currentNavID = +searchParams.get("nav");

  const fetchAllNavs = async () => {
    const data = await http.get("/api/flat-content-categories", {
      pagination: false,
      breadcrumb: `${process.env.REACT_APP_CX_ROOT_ID}/${process.env.REACT_APP_CX_ID}`,
      "breadcrumbDepth[lte]": 5,
    });
    setAllNavs(data["hydra:member"]);
  };

  useEffect(() => {
    fetchAllNavs();
  }, []);

  useEffect(() => {
    setCurrentNav(allNavs?.find((item) => item.id === currentNavID) ?? null);
  }, [allNavs, currentNavID]);

  useEffect(() => {
    if (allNavs) {
      if (currentNav) {
        if (!hasChildrens(currentNav, allNavs)) {
          navigate(`/${locale}/nav/${currentNav.id}`);
          return;
        }
        setNavChilds(getChildrens(currentNavID, allNavs));
      } else {
        setNavChilds(
          allNavs.filter(
            (item) =>
              item.parent ===
              `/api/flat-content-categories/${process.env.REACT_APP_CX_ID}`
          )
        );
      }
      handleBreadcrumb();
    }
  }, [allNavs, currentNav]);

  const handleBreadcrumb = () => {
    const bc = currentNav
      ? getTree(currentNav.id, null, allNavs).map((item) => ({
          link: `/?nav=${item.id}`,
          name: translateKey(item.names),
        }))
      : [];

    localStorage.setItem("breadcrumb", JSON.stringify(bc));
    setBreadcrumb(bc);
  };

  const handleNav = (item) => {
    if (!item) {
      navigate(`/${locale}/`);
      return;
    }

    if (isChannel(item)) {
      window.location.pathname = `/${locale}/nav/${item.id}`;
      return;
    }

    if (!hasChildrens(item, allNavs)) {
      if (item.color) {
        localStorage.setItem("color", item.color);
      } else if (!item.color && localStorage.getItem("color")) {
        localStorage.removeItem("color");
      }

      navigate(`/${locale}/nav/${item.id}`);
      return;
    }

    const params = new URLSearchParams(searchParams);

    if (params.has("nav")) {
      params.delete("nav");
    }
    params.set("nav", item.id);
    navigate(`/${locale}/?${params.toString()}`);
  };

  useEffect(() => {
    setNavChildsHasSubtitle(false);
    if (navChilds) {
      navChilds.forEach((item) => {
        if (
          item.values.category_subtitle?.[0].data &&
          navChildsHasSubtitle === false
        ) {
          setNavChildsHasSubtitle(true);
        }
      });
    }
  }, [navChilds, location.search]);

  return (
    <div className="flex flex-col h-full">
      <Header
        openDrawer={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <Banner />
      <div className="grow flex overflow-hidden h-full">
        <DrawerMenu
          isOpen={openDrawer}
          setIsOpen={setOpenDrawer}
          handleNav={handleNav}
        />
        <div className="grow flex flex-col overflow-hidden p-2 h-full">
          {breadcrumb.length > 0 && (
            <>
              <div className="flex justify-between items-end md:items-center py-4 border-b mb-4">
                <div className="mb-2">
                  <Breadcrumbs items={breadcrumb} showTitle />
                </div>
                {currentNav?.media && (
                  <a
                    href={`${currentNav.media}?download=1`}
                    className={
                      "break-words flex flex-col justify-center w-10 h-10 md:w-20 md:h-20 items-center bg-primary hover:bg-primary-400 transition-all duration-200 text-white text-xs gap-2 rounded font-lacosteExpandedBold"
                    }
                  >
                    <img
                      src={
                        currentNav.values?.category_link_icon?.[0].data?.url ||
                        iconPlay
                      }
                      alt="import"
                      width={15}
                      height={15}
                    />
                    <span className="hidden md:block uppercase whitespace-break-spaces">
                      {currentNav.values?.category_link_name?.[0].data ||
                        t("instore_assets")}
                    </span>
                  </a>
                )}
              </div>
            </>
          )}
          {navChilds ? (
            <div
              className={`grow flex flex-col gap-2 overflow-auto ${
                currentNavID ? "justify-start" : "justify-stretch"
              }`}
            >
              {navChilds.map((item) => {
                return (
                  <div key={item.id} className={currentNavID ? "" : "grow"}>
                    <NavBloc
                      item={item}
                      center={!navChildsHasSubtitle}
                      onClick={() => handleNav(item)}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex h-full justify-center items-center">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
